import * as React from "react";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import "../styles/impressum.styles.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

const DatenschutzPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LayoutComponent page={Page.AGB} seo={{ title: t("agb.title") }}>
      <div className="padding-wrapper-60">
        <h2 className="main-headline">
          <div
            dangerouslySetInnerHTML={{
              __html: t("agb.title"),
            }}
          />
          <span />
        </h2>
        <div
          className="text-wrapper--paragraph margin-top link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("agb.subTitle", { defaultValue: "" }),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.0"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.1"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.2"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.3"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.4"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.5"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.6"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.7"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.8"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.9"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.10"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.11"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.12"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.13"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.14"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.15"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.16"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.17"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.18"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.19"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.20"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.21"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.22"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.23"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.24"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.25"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.26"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.27"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.28"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.29"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.30"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.31"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.32"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.33"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.34"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.35"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.36"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.37"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.38"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.39"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.40"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.41"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.42"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.43"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.44"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.45"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.46"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.47"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.48"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.49"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.50"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.51"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.52"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.53"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.54"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.55"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.56"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.57"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.58"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.59"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.60"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.61"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.62"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.63"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.64"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.65"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.66"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.67"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.68"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.69"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.70"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.71"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.72"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.73"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.74"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.75"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.76"),
          }}
        />
        <div
          className="text-wrapper--paragraph bold margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.77"),
          }}
        />
        <div
          className="text-wrapper--paragraph"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.78"),
          }}
        />
        <div
          className="text-wrapper--paragraph link-wrapper margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.79"),
          }}
        />
        <div
          className="text-wrapper--paragraph margin-top"
          dangerouslySetInnerHTML={{
            __html: t("agb.content.80"),
          }}
        />
      </div>
    </LayoutComponent>
  );
};

export default DatenschutzPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
